import React, { useState} from 'react'
import './Testimonial.css';
import {testimonialsData} from "../../data/testimonialsData";
import leftArrow from '../../assets/leftArrow.png';
import rightArrow from '../../assets/rightArrow.png'
const Testimonial = () => {
    /*const transition = {type:};*/
    
    const [selected, setSelected] = useState(0);
    const tLenght = testimonialsData.length;
  return (
    <div className="testimonial" id='testimonials'>
        <div className="left-t">
            <span>Testimonial</span>
            <span className="stroke-text">what TOPPERS</span>
            <span>say about us</span>
            <span  style={{opacity: 1, transform: 'none'}}>
                {testimonialsData[selected].review}
            </span>
            <span>
                <span style={{color: 'var(--orange)'}}> 
                {testimonialsData[selected].name}
            </span>{" "}
            -{testimonialsData[selected].status}
            </span>
        </div>

        <div className="right-t">
            <div></div>
            <div></div>
            <img src={testimonialsData[selected].image} alt="" />
            <div className="arrows">
                <img
                onClick={()=>{
                    selected===0?setSelected(tLenght -1):
                    setSelected((prev)=> prev -1)
                }}
                src={leftArrow} alt="" />
                
                <img 
                 onClick={()=>{
                    selected===tLenght -1 ?setSelected(0):
                    setSelected((prev)=> prev +1)
                }}
                
                src={rightArrow} alt="" />
            </div>
        </div>

    </div>

  );
};

export default Testimonial
