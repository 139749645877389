import React from 'react'
import Header from '../Header/Header'
import './Hero.css'
import hero_image from "../../assets/hero_image.png";
import hero_image_back from "../../assets/hero_image_back.png";
import Heart from "../../assets/heart.png";
import calories from "../../assets/calories.png";
import {motion} from 'framer-motion'

const Hero = () => {
  const transition ={type: 'spring',duration :3}
  const mobile = window.innerWidth<=768 ? true:false;
  return (
    <div className="hero" id='home'>
         <div className="blur hero-blur"></div>

      <div className="left-h"> 
      <Header/>
      <div className="the-best-ad">
         <motion.div
           
           
          initial={{left: mobile?"160px":'238px'}}
          whileInView={{left:'9px'}}
          transition={{...transition, type: 'tween'}}
         ></motion.div>
        <div></div>
        <span>best coaching institude in muzaffarpur</span>
      </div>
    {/* heroheading*/}
     <div className="hero-text">
     <div>
       <span className='stroke-text'>the mission  </span>
       <span>classes </span>
       </div>
       <div>
       <span>and avinash hostal</span></div>
       <div className="">
        <span><b>CLASSES:-6TH,7TH,8TH,9TH,10TH,11TH,12TH(ALL subjects)</b><br></br></span>
        <span> 
       Sheakhpur Akharaghat Road,Om building Maiasthan in front of Vanasthali school muzaffarpur </span></div>
       
       </div>
      {/*figures */}
      <div className="figures">
        <div>
          <span>+10</span>
          <span>EXPERT FACULITIES</span>
        </div>
        <div>
          <span>+ 200</span>
          <span>STUDENTS</span>
        </div>
        <div>
          <span>+ 7</span>
          <span> PROGRAMS</span>
        </div>
      </div>
      {/*hero button */}
      <div className="hero-buttons">
        <button className="btn">FREE DEMO CLASSES</button>
        <button className="btn">Estd : 2010</button>
      </div>
       </div>
      <div className="right-h"> 
    <button className='btn'>join now</button>
     <motion.div
      initial={{right: "-1rem"}}
      whileInView={{right:"4rem"}}
     transition={transition}
     className="heart-rate">
      <img src={Heart} alt="" />
      <span>C.B.S.E and</span>
      <span>bihar board</span>
     </motion.div>
{/* hero imagesss */}
<img src={hero_image} alt="" className="hero-image"/>
<img src={hero_image_back} alt="" className="hero-image-back"/>
{/*calories */}
<div className="calories">
  <img src={calories} alt="" />
  <div>
  <span>MATHS expert</span>
  <span>AVINASH SIR</span>
  </div>
</div>
    </div>
  </div>
  );
};

export default Hero
