import React, { useRef } from "react"
import './Join.css';
import emailjs from '@emailjs/browser'
const Join = () => {
    const from = useRef()
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_g14532u', 'template_ysc55ox', from.current, '-6ilYNjFXVwZokhH0')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      };

  return (
     <div className="Join" id="join-us">
     <div className="left-j">
        <hr />
         <div>
        <span className="stroke-text">READY TO</span>
        <span> LEVEL UP</span>
         </div>
         <div>
        <span>Your knowledge</span>
        <span className="stroke-text"> WITH US?</span>
         </div>
     </div>
     <div className="right-j">
        <form ref={from} className="email-container" onSubmit={sendEmail}>
            <input type="email" name="user_email" placeholder="Enter your Email address"/>
            <button className="btn btn-j">join now</button>
        </form>
     </div>
     </div>
  );
};

export default Join
