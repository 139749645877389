import image1 from "../assets/t-image1.jpg";
import image2 from "../assets/t-image2.jpg";
import image3 from "../assets/t-image3.jpg";
import image4 from "../assets/t-image4.jpg";
import image6 from "../assets/t-image6.jpg";
import image7 from "../assets/t-image7.jpg";
import image8 from "../assets/t-image8.jpg";
import image9 from "../assets/t-image9.jpg";
import image10 from "../assets/t-image10.jpg";
import image5 from "../assets/t-image5.jpg";
export const testimonialsData = [
  
  {
    image: image1,
    review:
      "Avinash sir and his team is the best team in muzafarpur it helps me a lot to build intrest in maths ans science ,and due to it i crack IIT.. !",
    name: 'SUDHANSHU KUMAR',
    status : 'IIT KANPUR'
  },  
  {
    image: image2,
    review:
      " i had learn maths from avinash sir and he helped me to build foundation of maths and science for iit jee !",
    name: 'ARYAN RAJ',
    status : 'IIT KANPUR'
  },  
  {
    image: image3,
    review:
      " i was student of mission classes in batch of 2018, it was a wounderfull enviroments with best teachers of all subjects!",
    name: 'RAUSAN RANJAN',
    status : 'IIT DHANBAD'
  },  
  {
    image: image4,
    review:
      "i get my selection in neet-ug exam in very first attemp and i really thanksfull to Avinash sir and his team for such supports !",
    name: 'AYUSH KUMAR',
    status : 'MBBS'
  },  
  {
    image: image5,
    review:
      "i get my selection in neet-ug exam in very first attemp and i really thanksfull to Avinash sir and his team for such supports !",
    name: 'SANNY sahu',
    status : 'DELHI AIMS'
  },  
  {
    image: image6,
    review:
      "i was student of mission classes in batch of 2018, it was a wounderfull enviroments with best teachers of all subjects!",
    name: 'SATYAM kumar',
    status : 'NIT delhi'
  }, 
    
   {
    image: image7,
    review:
      " i was student of mission classes in batch of 2018, it was a wounderfull enviroments with best teachers of all subjects!",
    name: 'DHRAMPAL',
    status : 'NIT meghalya'
  },
  {
    image: image8,
    review:
      " i was student of mission classes in batch of 2018, it was a wounderfull enviroments with best teachers of all subjects!",
    name: 'KUNDAN',
    status : 'NIT meghlya'
  },
  {
    image : image9,
    review:"i was student of mission classes in batch of 2020, it was a wounderfull enviroments with best teachers of all subjects.",
    name: 'shikha bharti',
    status: "nit patna"
  },
  {
    image: image10,
    review:
      " i was student of mission classes in batch of 2018, it was a wounderfull enviroments with best teachers of all subjects!",
    name: 'SHREYA',
    status : 'Motihari engn.collage'
  }
];
