import './App.css';
import Hero from './compnents/Hero/Hero';
import Programs from './compnents/Programs/Programs';
import Reasons from './compnents/Reasons/Reasons';
import Plans from './compnents/Plans/Plans';
import Testimonial from './compnents/Testimonial/Testimonial';
import Join from './compnents/Join/Join';
import Footer from './compnents/Footer/Footer';

function App() {
  return (
    <div className="App">
      <Hero/>
      <Programs/>
      <Reasons/>
      <Plans/>
      <Testimonial/>
      <Join/>
      <Footer/>
       </div>
  );
}

export default App;
